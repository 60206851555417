<template lang="pug">
    .main-wrapper.pacotes-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Pacotes</b>
        Dialog.dialogApagar(header='Remover Pacote' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o Pacote?
            .ta-center.my-4
                Button.p-button-danger(label='Remover' @click='apagarPacote()')
        
        Dialog.dialogApagar(header='Desbloquear Pacote' :visible.sync='dialogDesbloquear' :modal='true')
            span Deseja desbloquear o Pacote <b>Pacote:{{dialogBloquear_data?.ds_pacote}}</b> ?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingBloquear' strokeWidth='6' style="height: 25px")
                Button(v-else label='Desbloquear' @click='desbloquearPacote()')
        
        Dialog.dialogBloquear(header='Bloquear Pacote' :visible.sync='dialogBloquear' :modal='true' @hide='dialogBloquear_data,motivo_bloqueio = null')
            .p-grid
                .p-col-12
                    label.form-label Motivo do bloqueio do pacote <b>Pacote:{{ dialogBloquear_data?.ds_pacote }}</b>
                    textarea.textarea-motivo(
                        v-model='motivo_bloqueio'
                        style='width: 100%; padding: 5px'
                        :autoResize="true"
                        rows="5" 
                        placeholder='Digite o motivo do bloqueio.'
                    )
                ProgressSpinner(v-if='waitingBloquear' strokeWidth='6' style="height: 25px")
                .p-col-12.ta-center(v-else)
                    Button(style='margin-right:10px' label='Bloquear' @click='bloquearPacote()')
                    Button.p-button-danger(label='Cancelar' @click='dialogBloquear = false; dialogBloquear_data = null;')
        
        Dialog.dialogBloquear(header='Motivo de Bloqueio' :visible.sync='dialogMotivo' :modal='true' @hide='dialogMotivo,motivo_bloqueio = null')
            .p-grid
                .p-col-12
                    label.form-label Motivo do bloqueio do pacote <b>Pacote:{{ dialogBloquear_data?.ds_pacote }}</b>
                    textarea.textarea-motivo(
                        v-model='motivo_bloqueio'
                        disabled
                        style='width: 100%; padding: 5px'
                        :autoResize="true"
                        rows="5" 
                        placeholder='Digite o motivo do bloqueio.'
                    )

                .p-col-12.ta-center
                    Button.p-button-danger(label='Fechar' @click='dialogMotivo = false; dialogBloquear_data = null; motivo_bloqueio = false;')

        Dialog.dialog-vigencias(:visible.sync="dialogVigencia.visible" :modal="true"
            :header="`Vigências (Pacote: ${ dialogVigencia.ds_pacote })`")
            .ta-center.my-4(v-if="dialogVigencia.waiting")
                ProgressBar(mode="indeterminate")
            .ta-center(v-else-if="! dialogVigencia.list.length")
                span Nenhuma vigência encontrada.
            div(v-else)
                DataTable(:value="dialogVigencia.list")
                    Column(headerStyle='width: 20%;' header='Vigencia' bodyStyle='padding:0;')
                        template(#body='props')
                            .cell(v-if="props.data.status == 0"
                                :class="{s3: true}") Atual
                            .cell(v-if="props.data.status == 1"
                                :class="{s4: true}") Posterior
                            .cell(v-if="props.data.status === 2"
                                :class="{s5: true}") Vencida
                    Column(headerStyle='width: 30%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center;' field='vigencia' header='Data')
                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center;' field='preco' header='Valor')
        
        Dialog.dialog-vigencias(:visible.sync="dialogProcedimentos.visible" :modal="true"
            :header="`Procedimentos (Pacote: ${ dialogProcedimentos.ds_pacote })`")
            .ta-center.my-4(v-if="dialogProcedimentos.waiting")
                ProgressBar(mode="indeterminate")
            .ta-center(v-else-if="! dialogProcedimentos.list.length")
                span Nenhum procedimento encontrado.
            div(v-else)
                DataTable(:value="dialogProcedimentos.list")
                    Column(headerStyle='width: 10%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center;' field='cd_procedimento' header='Código')
                    Column(headerStyle='width: 30%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center;' field='ds_procedimento' header='Procedimento')
                    Column(headerStyle='width: 20%; font-size:.9em' bodyStyle='font-size:.9em; text-align:center;' field='nm_fantasia' header='Estabelecimento')
        
       
        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='false')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-4
                    label.form-label Procedimentos:
                    ProgressBar(v-if='waitingProcedimentos' mode="indeterminate")
                    .p-inputgroup(v-else)
                        MultiSelect(v-model='filters.id_procedimento'
                            display="chip"
                            :options='options.procedimentos' dataKey='id' optionLabel='nm_procedimento' 
                            optionValue='id' placeholder='Selecione' 
                            :filter='options.procedimentos.length > 0' @change='applyFilters()')
                        
                .p-col-12.p-md-4
                    label.form-label Pacotes:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_pacote'
                            )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Estabelecimentos:
                    ProgressBar(v-if='waitingEstabelecimentos' mode="indeterminate")
                    .p-inputgroup(v-else)
                        MultiSelect(v-model='filters.cd_estabelecimento'
                            display="chip"
                            :options='options.estabelecimentos' dataKey='id' optionLabel='nm_fantasia' 
                            optionValue='id' placeholder='Selecione' 
                            :filter='options.procedimentos.length > 0' @change='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Bloqueados:
                    Dropdown(v-model='filters.ie_bloqueado'
                        :options='options.ie_bloqueado' dataKey='value' optionLabel='label' 
                        optionValue='value' placeholder='Selecione' @change='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Status:
                    Dropdown(v-model='filters.ie_status'
                        :options='options.ie_status' dataKey='value' optionLabel='label' 
                        optionValue='value' placeholder='Selecione' @change='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Vigente:
                    Dropdown(v-model='filters.ie_vigente'
                        :options='options.ie_vigente' dataKey='value' optionLabel='label' 
                        optionValue='value' placeholder='Selecione' @change='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label <br>
                    Button(label='Limpar filtros' :disabled='waiting' icon="jam jam-rubber" @click="clearFilters()")

        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/pacotes/salvar/0/')")
            // Button(label='Adicionar' icon="jam jam-plus" @click="dialogSelecionarCirurgia = true")
            
        ProgressBar(v-if='waiting' mode="indeterminate")
        Panel.datatable-panel(v-else header='Lista de pacotes')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            div(style="overflow-x: scroll")
                div(v-if='list.length == 0')
                    p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
                DataTable.datatable(v-else :value="list")
                    Column(headerStyle='width: 5%' bodyStyle='text-align:center' header='Status')
                        template(#body='props')
                            ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
                            .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data.id)')
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")

                    Column(headerStyle='width: 8em;' field='cd_pacote' header='Código' bodyStyle='text-align:center')
                    Column(headerStyle='width: 20em;' field='nm_estabelecimento' header='Estabelecimento(s)')
                        template(#body='props')
                            ul 
                                li(v-for='elem, idx in unifyEstabs(props.data.cd_estabelecimento)' :key='idx') {{ elem }}
                    Column(headerStyle='width: 18em;' bodyStyle='overflow-wrap: break-word;' field='ds_pacote' header='Pacotes')
                    Column(headerStyle='width: 5em;' field='ie_sigla' header='Sigla')
                    Column(headerStyle='width: 8em;' header='Preço')
                        template(#body='props')
                            .ta-center
                                p(style='margin: 5px;') {{ mostrarVigencia(props.data.valores).valor }}
                                p(style='color: gray; font-size: 90%; margin: 5px;') {{ mostrarVigencia(props.data.valores).data}}
                    Column(headerStyle='width: 15em; text-align: center' header="Ações")
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.p-button-success.mr-1(
                                    v-if='!props.data.ie_bloqueado'
                                    v-tooltip.top="'Bloquear'"
                                    icon="jam jam-padlock-f"
                                    @click="dialogBloquear = true; dialogBloquear_data = props.data;"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                    v-else
                                    v-tooltip.top="'Desbloquear'"
                                    icon="jam jam-padlock-open-f"
                                    @click="dialogDesbloquear = true; dialogBloquear_data = props.data;"
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/pacotes/salvar/${props.data.id}/`)"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
                                    v-tooltip.top="'Excluir'"
                                    icon="jam jam-minus-circle"
                                    @click="abrirApagar(props.data.id)"
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Histórico de Vigências'"
                                    icon="jam jam-clock"
                                    @click="openVigenciasDialog(props.data.id)"
                                    style='background-color: rebeccapurple; border: none;'
                                )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Procedimentos'"
                                    icon="jam jam-document"
                                    @click="openModalProcedimentos(props.data)"
                                    style='background-color:#312C66; border: none;'
                                )
                                Button.p-button-raised.p-button-rounded.p-button-warning(
                                    v-if='props.data.ie_bloqueado'
                                    v-tooltip.top="'Informações de bloqueio'"
                                    icon="jam jam-info"
                                    @click="dialogMotivo = true; dialogBloquear_data = props.data; motivo_bloqueio = props.data.ds_motivo_bloqueio"
                                )
                               
                    template(#empty)
                        ProgressBar.mt-2.mb-2(v-if='waiting' mode="indeterminate")
                        .ta-center(v-else) Nenhum registro encontrado.

            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
        BlockUI(:blocked="waitingImprimir" :fullScreen="true")

</template>

<style lang="scss">
.pacotes-listar {
    .dialogBloquear {
			width: 35vw;
	}
    .p-multiselect-panel {
        max-width: 600px !important;
        min-width: 380px !important;
        display: inline-grid;
        max-width: 330px;
        word-wrap: break-word;
        white-space: initial;
    }
    .waitingStatus,
    .waitingObservacoes {
        width: 29px;
        height: auto;
    }
    .datatable {
        min-width: 1100px;
    }
    .cell {
        padding: 16px 0;
        text-align: center;
        &.s1 {
            background-color: #eae9e9;
        }
        &.s2 {
            background-color: #fff1cc;
        }
        &.s3 {
            background-color: #daf2c2;
        }
        &.s4 {
            background-color: #00bdff;
        }
        &.s5 {
            background-color: #ffd8d2;
        }
    }
    .dialogApagar,
    .dialogCancelar,
    .dialogCancelarPagamento,
    .dialogVerHistorico {
        .p-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .dialogVerHistorico {
        max-width: 98%;
        min-width: 360px;
        max-height: 480px;
    }
    .dialogCancelar,
    .dialogCancelarPagamento {
        width: 96%;
        max-width: 480px;
    }
    .border {
        border: 1px solid #ccc;
        border-radius: 10px;
        margin: 5px;
        padding: 10px;
        min-height: 150px;
    }
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 23px;
        height: 23px;
        line-height: 36px;
        border-radius: 50%;
        background-color: #94c860;
        border: 1px solid #84b553;
        transition: 250ms;
        cursor: pointer;
        padding-right: 10px;
        &:hover {
            background-color: #84b553;
        }
        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
            &:hover {
                background-color: #b33030;
            }
        }
        .jam {
            color: #fff;
            margin-left: 2px;
        }
    }
    .input-situacao .p-multiselect-header {
        display: none;
    }
    .dialog-movimentacoes {
        width: 800px;
        max-width: 98%;
    }
    .dialog-opcoes-impressao {
        width: 500px;
        max-width: 96%;
        overflow: auto;
    }
    .dialog-vigencias {
        width: 600px;
        max-width: 98%;
    }
}
.p-tooltip {
    max-width: none;
}
</style>

<script>
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import Panel from "primevue/panel";
import Paginator from "primevue/paginator";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Tooltip from "primevue/tooltip";
// import CustomMultiSelect from "../CustomComponents/CustomMultiSelect";

import {
    Orcamento,
    Pacote,
    Estabelecimento,
    Procedimentos
} from "../../middleware";
import wsConfigs from "./../../middleware/configs";
import moment from "moment";
import { pCalendarLocale_ptbr } from "@/utils";

export default {
    components: {
        BlockUI,
        Button,
        Calendar,
        Column,
        DataTable,
        Dialog,
        Dropdown,
        InputText,
        MultiSelect,
        Panel,
        Paginator,
        ProgressBar,
        ProgressSpinner,
        Textarea,
        Tooltip,
        // CustomMultiSelect
    },
    created() {
        this.applyFilters()
        this.waitingEstabelecimentos = true;
        Estabelecimento.findAll()
        .then(response => {
            this.waitingEstabelecimentos = false;
            if(([200,201,204]).includes(response.status)){
                this.options.estabelecimentos = response.data;
            }else{
                this.$toast.error("Erro nos procedimentos")
            }
        })
        this.waitingProcedimentos = true;
        Procedimentos.findAll()
        .then(response => {
            this.waitingProcedimentos = false;
            if(([200,201,204]).includes(response.status)){
                this.options.procedimentos = response.data;
            }else{
                this.$toast.error("Erro nos estabelecimentos")
            }
        })
    },
    directives: { tooltip: Tooltip },
    watch: {
        "filters.id_procedimento": function(filter) {
            if (filter && filter.length == 0)
                this.filters.id_procedimento = null;
        },
        "filters.cd_estabelecimento": function(filter) {
            if (filter && filter.length == 0)
                this.filters.cd_estabelecimento = null;
        }
    },
    data() {
        return {
            ptbr: pCalendarLocale_ptbr,
            windowInnerWidth: window.innerWidth,
            usuario: null,
            waiting: true,
            waitingProcedimentos: false,
            waitingEstabelecimentos: false,
            waitingApagar: false,
            waitingStatus: false,
            waitingImprimir: false,
            waitingCancelar: false,
            waitingCancelarPagamento: false,
            waitingBloquear: false,
            dialogBloquear_data: null,
            motivo_bloqueio: null,
            list: [],
            showList: [],
            filters: {
                cd_estabelecimento: null,
                id_procedimento: null,
                ds_pacote: null,
                ie_bloqueado: false,
                ie_vigente: true,
                ie_status: true,
            },
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            options: {
                estabelecimentos: [],
                procedimentos: [],
                ie_bloqueado: [
                    { label: "TODOS", value: null },
                    { label: "Não bloqueado", value: false },
                    { label: "Bloqueado", value: true }
                ],
                ie_vigente: [
                    { label: "TODOS", value: null },
                    { label: "Não Vigente", value: false },
                    { label: "Vigente", value: true }
                ],
                ie_status: [
                    { label: "TODOS", value: null },
                    { label: "Desabilitado", value: false },
                    { label: "Habilitado", value: true }
                ]
            },
            dialogApagar: false,
            dialogBloquear: false,
            dialogDesbloquear: false,
            dialogApagar_data: {},
            dialogMotivo: false,
            dialogSelecionarCirurgia: false,
            dialogVerHistorico: false,
            dialogVerHistorico_data: {},
            dialogCancelar: false,
            dialogCancelar_data: {},
            dialogCancelarPagamento: false,
            dialogCancelarPagamento_data: {},
            dialogVigencia: {
                visible: false,
                waiting: false,
                id: null,
                list: []
            },
            dialogOpcoesImpressao: {
                visible: false,
                id: null,
                waiting: false
            },
            dialogProcedimentos: {
                visible: false,
                waiting: false,
                list: []
            },
        };
    },
    methods: {
        getList(params) {
            this.waiting = true;
            this.list = [];
            // let aux = [];
            return Pacote.findAll(params).then(response => {
                this.waiting = false;
                if(([200,201,204]).includes(response.status)){
                    this.paginator.count = response.data.count
                    this.list = response.data.results
                    this.list = response.data.results;
                    this.list.forEach((item, index) => {
                        this.list[index] = {
                            ...item,
                            valores: this.classificarVigencias(item.valores)
                        };
                    });
                }
                return true;
            });
        },
        applyFilters(page) {
            this.paginator.page = page || 1;

            let params = {
                paginacao: true,
                page: this.paginator.page,
                per_page: this.paginator.per_page
            };
            if (this.$route.query.pg != this.paginator.page)
                this.$router.replace({ query: { pg: this.paginator.page } });

            Object.keys(this.filters).forEach(key => {
                if (this.filters[key] || this.filters[key] === false) {
                    if (key.substr(0, 3) === "dt_") {
                        params[key + "_inicial"] = moment(
                            this.filters[key][0]
                        ).format("YYYY-MM-DD");
                        if (this.filters[key][1]) {
                            params[key + "_final"] = moment(
                                this.filters[key][1]
                            ).format("YYYY-MM-DD");
                        }
                    } else if (
                        !Array.isArray(this.filters[key]) ||
                        this.filters[key].length > 0
                    ) {
                        params[key] = this.filters[key];
                    }
                }
            });
            this.getList(params);
        },
        onPage(ev) {
            this.applyFilters(ev.page + 1);
        },
        checkEmptyField(field) {
            if (!this.filters[field].length) this.applyFilters();
        },
        clearFilters() {
            Object.keys(this.filters).forEach(k => (this.filters[k] = null));
            this.applyFilters();
        },
        openHistorico(data) {
            this.dialogVerHistorico_data = { waiting: true };
            this.dialogVerHistorico = true;
            Orcamento.findAll({ nr_cpf: data.cd_paciente.nr_cpf }).then(
                response => {
                    this.dialogVerHistorico_data.waiting = false;
                    if (response.status === 200)
                        this.dialogVerHistorico_data.list = response.data;
                }
            );
        },
        imprimirOrcamento(id, medico = false) {
            this.waitingImprimir = true;
            Orcamento.imprimir(id, { pdf: true, medico }).then(response => {
                this.waitingImprimir = false;
                if ([200, 201, 204].includes(response.status)) {
                    let blob = new Blob([response.data], {
                        type: "application/pdf; charset=utf-8"
                    });
                    const fileURL = URL.createObjectURL(blob);
                    let a = window.open(fileURL);
                    a.print();
                    this.dialogOpcoesImpressao.visible = false;
                } else if (response.data.detail) {
                    if (typeof response.data.detail == "string")
                        this.$toast.error(response.data.detail, {
                            duration: 3000
                        });
                    else
                        response.data.detail.forEach(msg =>
                            this.$toast.error(msg, { duration: 3000 })
                        );
                }
            });
        },
        cancelarOrcamento() {
            if (!this.dialogCancelar_data.ds_informacoes) {
                this.$toast.error("Digite o motivo do cancelamento.");
                return;
            }

            let dataSend = {
                ds_informacoes: this.dialogCancelar_data.ds_informacoes
            };

            this.waitingCancelar = true;
            Orcamento.cancelar(this.dialogCancelar_data.id, dataSend).then(
                response => {
                    this.waitingCancelar = false;
                    if ([200, 201].includes(response.status)) {
                        this.dialogCancelar_data.cd_orcamento_situacao =
                            response.data.cd_orcamento_situacao;
                        this.dialogCancelar = false;
                        this.dialogCancelar_data = {};
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == "string")
                            this.$toast.error(response.data.detail, {
                                duration: 3000
                            });
                        else
                            response.data.detail.forEach(msg =>
                                this.$toast.error(msg, { duration: 3000 })
                            );
                    }
                }
            );
        },
        cancelarPagamento() {
            if (!this.dialogCancelarPagamento_data.ds_informacoes) {
                this.$toast.error("Digite o motivo do cancelamento.");
                return;
            }

            let dataSend = {
                ds_informacoes: this.dialogCancelarPagamento_data.ds_informacoes
            };

            this.waitingCancelarPagamento = true;
            Orcamento.cancelarPagamento(
                this.dialogCancelarPagamento_data.id,
                dataSend
            ).then(response => {
                this.waitingCancelarPagamento = false;
                if ([200, 201, 204].includes(response.status)) {
                    this.$toast.success("Pagamento cancelado com sucesso.");
                    this.dialogCancelarPagamento_data.cd_orcamento_situacao =
                        response.data.cd_orcamento_situacao;
                    this.dialogCancelarPagamento_data = {};
                    this.dialogCancelarPagamento = false;
                } else if (response.data.detail) {
                    if (typeof response.data.detail == "string")
                        this.$toast.error(response.data.detail, {
                            duration: 3000
                        });
                    else
                        response.data.detail.forEach(msg =>
                            this.$toast.error(msg, { duration: 3000 })
                        );
                }
            });
        },
        apagarPacote() {
            this.waiting = true;
            Pacote.remove(this.idASerApagado).then(response => {
                if(([200,201,204]).includes(response.status)){
                    this.waiting = false
                    this.dialogApagar = false;
                    this.applyFilters();                 
                    this.$toast.success("Pacote removido com sucesso.", {
                        duration: 3000
                    });       
                    return
                }
                this.$toast.error("Não foi possível remover o pacote.", {
                    duration: 3000
                });
            })
        },
        abrirApagar(id) {
            this.idASerApagado = id;
            this.dialogApagar = true;
        },
        alterarStatus(id) {
            let pacote = this.list.find(a => a.id === id);
            this.waitingStatus = id;
            Pacote.save({ id, ie_status: !pacote.ie_status }).then(response => {
                this.waitingStatus = 0;
                if (response.status === 200) {
                    pacote.ie_status = !pacote.ie_status;
                    if (pacote.ie_status)
                        this.$toast.success(`${pacote.ie_tipo_pacote == 'P'? 'Pacote' : 'Check-up'} habilitado`, {
                            duration: 3000
                        });
                    else
                        this.$toast.info(`${pacote.ie_tipo_pacote == 'P'? 'Pacote' : 'Check-up'} desabilitado`, {
                            duration: 3000
                        });
                } else if (response.status === 400) {
                    this.$toast.error("Não foi possível modificar o status", {
                        duration: 3000
                    });
                }
            });
        },
        classificarVigencias(vigencias) {
            let auxVig = vigencias.map(item => {
                let status = 2;
                if (item.dt_fim_vigencia) {
                    if (
                        moment().isBetween(
                            moment(item.dt_inicio_vigencia),
                            moment(item.dt_fim_vigencia),
                            undefined,
                            "[]"
                        )
                    )
                        status = 0;
                } else if (moment().isAfter(moment(item.dt_inicio_vigencia)))
                    status = 0;
                if (moment().isBefore(moment(item.dt_inicio_vigencia)))
                    status = 1;
                return {
                    ...item,
                    status: status
                };
            });
            return auxVig;
        },
        mostrarVigencia(vigencias) {
            let vigencia = vigencias.find(item => item.status == 0);
            if (!vigencia) {
                const proximas = vigencias.filter(item => item.status == 1);
                if (proximas.length > 0)
                    vigencia = proximas.sort(
                        (a, b) =>
                            moment(a.dt_inicio_vigencia).format("YYYYMMDD") -
                            moment(b.date).format("YYYYMMDD")
                    )[0];
            }
            if (vigencia)
                return {
                    data: this.montarVigencia(
                        vigencia.dt_inicio_vigencia,
                        vigencia.dt_fim_vigencia
                    ),
                    valor: "R$ " + vigencia.nr_preco
                };
            return "-";
        },
        openModalProcedimentos (data) {
            this.dialogProcedimentos = {
                visible: true,
                waiting: false,
                list: data.itens_pacote,
                ds_pacote: data.ds_pacote
                
            }
        },
        openVigenciasDialog(id_pacote) {
            const auxitem = this.list.find(item => item.id == id_pacote);
            if (auxitem) {
                this.dialogVigencia = {
                    visible: true,
                    waiting: false,
                    id: id_pacote,
                    list: auxitem.valores
                        .sort(
                            (a, b) =>
                                moment(a.dt_inicio_vigencia).format(
                                    "YYYYMMDD"
                                ) - moment(b.date).format("YYYYMMDD")
                        )
                        .map(item => ({
                            vigencia: this.montarVigencia(
                                item.dt_inicio_vigencia,
                                item.dt_fim_vigencia
                            ),
                            preco: "R$ " + item.nr_preco,
                            status: item.status
                            // status:
                            //     item.status == 0
                            //         ? "Atual"
                            //         : item.status == 1
                            //         ? "Anterior"
                            //         : "Posterior"
                        }))
                };
            }
        },
        montarVigencia(inicio, fim) {
            return `${moment(inicio).format("DD/MM/YYYY")} - ${
                fim ? moment(fim).format("DD/MM/YYYY") : ""
            }`;
        },
        bloquearPacote(){
            this.waitingBloquear = true;
            Pacote.blockPacote(this.dialogBloquear_data.id, { motivo_bloqueio: this.motivo_bloqueio }).then(response => {
                this.waitingBloquear = false
                if(([200,201]).includes(response.status)){
                    this.$toast.success("Bloqueio realizado com sucesso")
                    this.dialogBloquear_data = false;
                    this.dialogBloquear = false;
                    this.applyFilters()
                }else{
                    this.$toast.error("Erro no bloqueio")
                }
            })
        },
        desbloquearPacote(){
            this.waitingBloquear = true;
            Pacote.unblockPacote(this.dialogBloquear_data.id).then(response => {
                this.waitingBloquear = false;
                if(([200,201]).includes(response.status)){
                    this.$toast.success("Desbloqueio realizado com sucesso")
                    this.dialogBloquear_data = false;
                    this.dialogDesbloquear = false;
                    this.applyFilters()
                }else{
                    this.$toast.error("Erro no bloqueio")
                }
            })
        },
        unifyEstabs(estabelecimentos){
            return [...new Set(estabelecimentos.map(item => (item.nm_fantasia)))];
        },

    }
};
</script>
